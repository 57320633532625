/* freeCodeCamp theme colors: */
/* Admittedly, the styles are kind of all over the place.
 Refactoring and reorganizing this thing would be great. */
body {
  padding-bottom: 65px;
  margin: 0;
  background: #fafafa;
  background-size: cover;
  color: #141414;
  text-align: center;
  font-size: 30px; }
  body .errorsBox {
    color: #E60036; }
  body a {
    color: #42DB9D; }
    body a:hover {
      color: #00BF72; }
  body code[class*="language-"] .token.string {
    color: #04A470; }

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  text-align: left;
  background: #00BF72;
  display: flex;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-weight: bold; }
  .header img {
    width: 215px;
    margin-left: 10px;
    margin-top: -1px; }
  .header span {
    margin-left: 12px;
    color: white; }
  .header .contributeLink {
    position: absolute;
    height: 60px;
    right: 0;
    padding: 8px 15px 0 15px;
    color: white;
    text-decoration: none; }
    .header .contributeLink i {
      margin-left: 5px; }
    .header .contributeLink:hover {
      text-decoration: none;
      background: white;
      color: #42DB9D;
      border-bottom: 1px solid #42DB9D; }

.studyComponent {
  width: 550px;
  margin: 100px auto; }
  .studyComponent h1 {
    margin: 25px auto;
    font-family: 'Nunito', serif; }
  .studyComponent p {
    font-family: 'Nunito', serif; }
  .studyComponent .quizContainer {
    min-height: 42px;
    display: flex;
    width: 500px;
    margin: 15px auto;
    font-size: 30px;
    border: 1px solid black;
    font-family: 'Lato';
    color: #2A2A2A; }
    .studyComponent .quizContainer .review {
      padding: 5px 15px 5px 15px;
      color: #2A2A2A; }
      .studyComponent .quizContainer .review:hover {
        text-decoration: none;
        background: #42DB9D;
        color: white; }
    .studyComponent .quizContainer .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      padding-left: 15px;
      width: 100%;
      text-align: left;
      color: #2A2A2A; }
      .studyComponent .quizContainer .title span {
        margin-right: 5px;
        font-size: 18px; }
      .studyComponent .quizContainer .title:hover {
        text-decoration: none;
        background: #42DB9D;
        color: white; }
    .studyComponent .quizContainer .titleHover {
      background: #42DB9D;
      color: white;
      cursor: pointer; }

.studyWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  color: #2A2A2A;
  overflow: scroll; }
  .studyWrapper .about {
    margin-top: 85px;
    padding: 5px 25px; }
    .studyWrapper .about p {
      max-width: 625px;
      margin: 20px auto;
      font-size: 22px; }
    .studyWrapper .about .aboutContribute a {
      color: inherit;
      text-decoration: none; }
      .studyWrapper .about .aboutContribute a:hover {
        text-decoration: none;
        color: inherit; }
  .studyWrapper .studyContainer {
    font-family: 'Nunito', serif;
    padding-bottom: 50px; }
    .studyWrapper .studyContainer #score-tower {
      position: fixed;
      top: 60px;
      height: 5px;
      width: 100%;
      display: flex;
      flex-direction: row; }
      .studyWrapper .studyContainer #score-tower div {
        height: 5px; }
      .studyWrapper .studyContainer #score-tower .blank {
        background: #2A2A2A; }
      .studyWrapper .studyContainer #score-tower .success {
        background: #2DB9FF; }
      .studyWrapper .studyContainer #score-tower .failure {
        background: #E60036; }
    .studyWrapper .studyContainer .quizHeader {
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 0;
      width: 100%;
      height: 60px;
      background: #00BF72;
      color: white;
      font-family: 'Lato', sans-serif;
      font-weight: bold; }
      .studyWrapper .studyContainer .quizHeader .quizTitle {
        font-size: 28px;
        width: 40%;
        text-align: left;
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 5px; }
        .studyWrapper .studyContainer .quizHeader .quizTitle span {
          font-size: 30px; }
        .studyWrapper .studyContainer .quizHeader .quizTitle img {
          width: 275px;
          margin-right: 15px;
          margin-top: -1px; }
      .studyWrapper .studyContainer .quizHeader .quizMeta {
        width: 20%;
        font-size: 22px;
        text-align: center; }
      .studyWrapper .studyContainer .quizHeader #return {
        width: 40%;
        padding-right: 10px;
        text-align: right; }
        .studyWrapper .studyContainer .quizHeader #return a {
          color: white; }
          .studyWrapper .studyContainer .quizHeader #return a:hover {
            color: #E60036;
            cursor: pointer; }
    .studyWrapper .studyContainer .questionTitle {
      font-size: 30px;
      width: 950px;
      margin: 100px auto 25px auto; }
      .studyWrapper .studyContainer .questionTitle pre {
        width: 600px;
        font-family: 'Ubuntu Mono', monospace;
        margin: 15px auto;
        text-align: left;
        line-height: 18px;
        font-size: 16px; }
      .studyWrapper .studyContainer .questionTitle code[class*="language-"] {
        line-height: 1.25;
        -moz-tab-size: 2;
        -o-tab-size: 2;
        tab-size: 2; }
    .studyWrapper .studyContainer .choice {
      width: 650px;
      font-size: 24px;
      margin: 12px auto;
      padding-bottom: 5px;
      text-align: left;
      border: 1px solid #2A2A2A;
      color: #2A2A2A; }
      .studyWrapper .studyContainer .choice p {
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 8px;
        margin-bottom: 8px;
        font-family: 'Nunito', serif;
        font-size: 22px; }
      .studyWrapper .studyContainer .choice pre {
        font-family: 'Ubuntu Mono', monospace;
        margin-top: 0; }
    .studyWrapper .studyContainer .choice.solution {
      color: white;
      background: #42DB9D; }
    .studyWrapper .studyContainer .desktop:hover {
      color: white;
      background: #42DB9D;
      cursor: pointer; }
    .studyWrapper .studyContainer .selected {
      color: white;
      background: #42DB9D; }
    .studyWrapper .studyContainer .choice.review:hover {
      cursor: default; }
    .studyWrapper .studyContainer .choice.solution:hover {
      border: 1px solid #42DB9D;
      cursor: default; }
    .studyWrapper .studyContainer #correctWinner {
      background: #00BF72;
      color: white; }
    .studyWrapper .studyContainer #wrongWinner {
      background: #595555;
      color: white; }
    .studyWrapper .studyContainer #correctLoser {
      background: #42DB9D;
      border: 1px solid black;
      color: white; }
    .studyWrapper .studyContainer #wrongLoser {
      background: #E60036;
      border: 1px solid black;
      color: black; }
    .studyWrapper .studyContainer .correctAnswer {
      color: #2DB9FF; }
    .studyWrapper .studyContainer .wrongAnswer {
      color: #E60036; }
    .studyWrapper .studyContainer .explanation {
      width: 600px;
      margin: 15px auto; }
      .studyWrapper .studyContainer .explanation h3 {
        font-weight: bold; }
      .studyWrapper .studyContainer .explanation p {
        font-size: 20px;
        text-align: left; }
    .studyWrapper .studyContainer button {
      padding: 2px 4px;
      width: 350px;
      margin: 15px;
      background: white;
      color: #42DB9D;
      border: 1px solid #42DB9D;
      border-radius: 30px; }
    .studyWrapper .studyContainer button:hover {
      background: #42DB9D;
      color: white; }
    .studyWrapper .studyContainer #infoBox {
      position: fixed;
      right: 15px;
      bottom: 5px; }
      .studyWrapper .studyContainer #infoBox p {
        font-size: 15px;
        color: #2A2A2A; }
  .studyWrapper .scoreMessage {
    margin: 125px auto 50px auto; }
  .studyWrapper .fbShare a {
    color: inherit;
    text-decoration: none; }
    .studyWrapper .fbShare a:hover {
      text-decoration: none;
      color: inherit; }

.reviewContainer {
  padding-bottom: 75px; }

@media only screen and (max-width: 1275px) {
  .studyWrapper .studyContainer .quizHeader .quizTitle img {
    width: 150px;
    padding-top: 3px; }
  .studyWrapper .studyContainer .quizHeader .quizTitle span {
    font-size: 18px; } }

@media only screen and (max-width: 800px) {
  .studyWrapper .studyContainer .quizHeader {
    height: 75px;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .studyWrapper .studyContainer .quizHeader .quizTitle {
      text-align: center;
      padding: 2px;
      margin: 0;
      width: 100%; }
      .studyWrapper .studyContainer .quizHeader .quizTitle img {
        width: 150px;
        padding-top: 3px; }
      .studyWrapper .studyContainer .quizHeader .quizTitle span {
        font-size: 18px; }
    .studyWrapper .studyContainer .quizHeader .quizMeta {
      width: 100%;
      font-size: 12px; }
    .studyWrapper .studyContainer .quizHeader h3 {
      margin: 8px 0 15px 0; }
    .studyWrapper .studyContainer .quizHeader #return {
      width: 30%;
      padding-right: 6px;
      padding-top: 5px;
      font-size: 22px; } }

@media only screen and (max-width: 650px) {
  .header {
    height: 60px;
    justify-content: center; }
    .header img {
      width: 150px;
      margin-left: 10px; }
    .header span {
      margin-left: 12px;
      font-size: 15px;
      padding-top: 7px; }
  .studyComponent {
    width: 95vw; }
    .studyComponent h1 {
      width: 90vw;
      margin: 20px auto;
      font-size: 28px; }
    .studyComponent p {
      width: 65vw;
      margin: 5px auto;
      font-size: 22px; }
    .studyComponent .quizContainer {
      width: 300px;
      margin: 15px auto;
      font-size: 20px; }
      .studyComponent .quizContainer .review {
        padding: 5px 5px 5px 20px; }
      .studyComponent .quizContainer .title {
        padding: 5px;
        padding-left: 15px;
        font-size: 16px; }
        .studyComponent .quizContainer .title span {
          font-size: 12px; }
        .studyComponent .quizContainer .title:hover {
          text-decoration: none;
          background: white;
          color: #2A2A2A; }
  .studyWrapper .about h1 {
    font-size: 24px; }
  .studyWrapper .about p {
    font-size: 16px; }
  .studyWrapper .studyContainer {
    padding-bottom: 50px; }
    .studyWrapper .studyContainer #score-tower {
      top: 75px; }
    .studyWrapper .studyContainer .questionTitle {
      font-size: 22px;
      width: 90vw;
      margin-bottom: 25px; }
      .studyWrapper .studyContainer .questionTitle pre {
        width: 90vw;
        margin: 15px auto;
        line-height: 16px;
        font-size: 12px;
        white-space: pre-line;
        word-wrap: break-word;
        overflow: hidden; }
      .studyWrapper .studyContainer .questionTitle code[class*="language-"] {
        font-size: 12px;
        line-height: 1;
        -moz-tab-size: 1;
        -o-tab-size: 1;
        tab-size: 1; }
    .studyWrapper .studyContainer .choice {
      width: 95vw;
      margin: 8px auto;
      padding-bottom: 5px; }
      .studyWrapper .studyContainer .choice p {
        padding-left: 12px;
        font-size: 16px; }
      .studyWrapper .studyContainer .choice pre {
        overflow: hidden; }
    .studyWrapper .studyContainer .reviewTitle {
      width: 90vw;
      margin: 45px auto; }
    .studyWrapper .studyContainer button {
      width: 80vw;
      font-size: 26px; }
      .studyWrapper .studyContainer button:hover {
        background: white;
        color: green; }
    .studyWrapper .studyContainer .explanation {
      width: 85vw;
      margin: 15px auto; }
      .studyWrapper .studyContainer .explanation h3 {
        font-weight: bold; }
      .studyWrapper .studyContainer .explanation p {
        font-size: 16px; }
    .studyWrapper .studyContainer .messageDiv h1 {
      font-size: 24px; }
    .studyWrapper .studyContainer .scoreMessage {
      width: 90vw;
      margin: 125px auto 25px auto;
      font-size: 20px; } }
